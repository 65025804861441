<template>
  <div
    class="noShow"
    style="
      width: 100%;
      height: 66px;
      background: white;
      position: fixed;
      z-index: 120;
    "
  >
    <div class="header">
      <div class="title">
        <img :src="logoSrc" alt="" />
        {{ title }}
        <el-cascader
          :options="options"
          placeholder="请选择区县"
          clearable
          v-model="areaCodeTop"
          size="mini"
          :props="props"
          :show-all-levels="false"
          @change="xzqChange"
          style="width: 150px; line-height: 57px"
        ></el-cascader>
      </div>

      <div class="menu">
        <ul>
          <li
            v-for="item of menuList"
            :key="item.code"
            :class="item.label == currentMenu ? 'active' : ''"
          >
            <a @click="menuHandle(item.label)">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <!-- 聊天框 -->
      <div>
        <el-badge
          :value="AllMessages == 0 ? '' : AllMessages"
          :max="99"
          class="item"
        >
          <el-button size="small" @click="Online()">消息</el-button>
        </el-badge>
      </div>
      <div class="operation" v-if="!showOpt || !identityObj.identityName">
        <!-- <div class="search">
          <el-input
            style="width: 290px"
            v-model="keyword"
            placeholder="请输入关键词"
          ></el-input>
          <el-button type="primary">
            <img src="../../assets/icon-search.png" />
          </el-button>
        </div> -->
        <div class="login">
          <span @click="userLogin" class="with-icon">
            <img src="../../assets/icon-user.png" />
            登录
          </span>
          <span @click="userRegister">注册</span>
        </div>
      </div>

      <div class="user" v-if="showOpt && identityObj.identityName">
        <el-dropdown @command="handleCommand">
          <div class="photo">
            <!-- <img :src="imgSrc" alt="" /> -->
            <span>{{ identityObj.identityName }}</span>
            <span style="color: #00a0e9" v-if="identityObj.identityTypeDesc"
              >({{ identityObj.identityTypeDesc }})</span
            >，你好
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">会员中心</el-dropdown-item>
            <el-dropdown-item command="1">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { getStore } from "../../js/utils/store";
import { mapGetters, mapMutations } from "vuex";
import { getXzqArr, getUserMenuByIdentity } from "@/api/add";
import { removeEmptyChildren } from "@/js/utils/util";
import { UnreadMessage } from "../../api/websocket";
import { Utils } from "@/assets/js/util.js";
// import {initWebSocket} from '@/util/websocket'

const menuList = [
  {
    code: 1,
    name: "首页",
    label: "homepage",
  },
  {
    code: 2,
    name: "生产服务",
    label: "productionService",
  },
  {
    code: 3,
    name: "资源资产服务",
    label: "resourcesService",
  },
  {
    code: 4,
    name: "金融服务",
    label: "financialService",
  },
];

const countyList = [
  {
    code: 1,
    name: "宣汉县",
  },
  {
    code: 2,
    name: "开江县",
  },
  {
    code: 3,
    name: "大竹县",
  },
  {
    code: 4,
    name: "渠县",
  },
  {
    code: 5,
    name: "通川区",
  },
  {
    code: 6,
    name: "达川区",
  },
  {
    code: 7,
    name: "万源市",
  },
];
export default {
  data() {
    return {
      options: [],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
      },
      keyword: "",
      showOpt: getStore({ name: "access_token" }),
      imgSrc: "",
      isReload: true,
      logoSrc: window.pageConfiguration.homeLogo,
      title: window.pageConfiguration.title,
      menuList,
      countyList,
      currentMenu: "homepage",
      value: 1,
      areaCodeTop: "",
      MessagesReceived: "",
      websocketUrl: window.globalUrl.WEBSOCKET_URL,
    };
  },
  watch: {
    $route: {
      handler: function (newV) {
        this.currentMenu = newV.name;
        this.showOpt = getStore({ name: "access_token" });
        if (this.showOpt) {
          // this.initAvatar();
        }
      },
      immediate: true,
    },
  },
  created() {
    // this.UnMessage();
    this.getRegions();
    this.webSocketInit();
    setTimeout(() => {
      this.areaCodeTop = "" + this.topSelectAreacode;
    }, 100);
  },
  mounted() {
    this.getRegions();
    setTimeout(() => {
      this.areaCodeTop = "" + this.topSelectAreacode;
    }, 100);
    var that = this;
    Utils.$on("demo", function (msg) {
      console.log(msg);
      that.webSocketInit();
    });
  },
  computed: {
    ...mapGetters([
      "identityObj",
      "userInfo",
      "vipLeftTree",
      "topSelectAreacode",
      "AllMessages",
    ]),
  },
  methods: {
    ...mapMutations(["setTopSelectAreacode", "SET_MESSAGE", "setVipTree"]),
    //建立连接
    webSocketInit() {
      // if(this.webSocketObject){
      //   return;
      // }
      this.openid = JSON.parse(localStorage.getItem("access_token"));
      let autoId = this.identityObj.identityId;
      if (!autoId) {
        return;
      }
      const webSocketUrl =
        `${this.websocketUrl}/${autoId}/computer?authorization=` +
        this.openid.content;
      console.log(webSocketUrl);

      this.webSocketObject = new WebSocket(webSocketUrl);
      this.webSocketObject.onopen = this.webSocketOnOpen;
      this.webSocketObject.onmessage = this.webSocketOnMessage;
      this.webSocketObject.onclose = this.webSocketOnClose;
      this.webSocketObject.onerror = this.webSocketOnError;
     
    },
    //连接打开
    webSocketOnOpen(e) {
      console.log("与服务端连接打开->", e);
      if (this.webSocketObject.readyState == this.webSocketObject.CONNECTING) {
        console.log("连接正在打开");
      }
      if (this.webSocketObject.readyState == this.webSocketObject.OPEN) {
        console.log("连接已打开");
        this.UnMessage();
      }
      // header页面传入choose页面的方法
      //开启心跳
      // this.start();
    },
    //调用chat页面的方法
    updateChat(e) {
      Utils.$emit("updateChat", e);
    },
    //调用chat页面的方法
    updatelist(e) {
      Utils.$emit("updatelist", e);
    },
    //调用chat页面的方法
    upisChange(e) {
      Utils.$emit("upisChange", e);
    },
    //调用dbspDetails页面的方法
    dbspDetails(e) {
      Utils.$emit("dbspDetails", e);
    },

    // 消息监听
    webSocketOnMessage(e) {
      console.log("来自服务端的消息->", JSON.parse(e.data).type);
      if (JSON.parse(e.data).type == "agreeProcess") {
        this.dbspDetails(e);
      } else {
        let msgData = JSON.parse(e.data);

        // JSON.parse()
        // console.log();
        // console.log("msgData->111111",JSON.parse(msgData.content).receiverId );
        // debugger
        if (
          this.identityObj.identityId != JSON.parse(msgData.content).receiverId
        ) {
          console.log("来自服务端的消息->return");

          return false;
        }
        if (JSON.parse(e.data).type == "new message") {
          //调用chat页面里面接受到的新消息
          // Utils.$on('MessagesReceived',data=>{
          //   this.MessagesReceived=data
          // })
          // console.log(this.MessagesReceived)
          // this.updateChat(this.MessagesReceived);
          this.updateChat(e);
          //消息未读总数
          this.UnMessage();
        } else if (JSON.parse(e.data).type == "isAgree") {
          console.log("isAgree1111");
          this.updatelist(e);
          //消息未读总数
          this.UnMessage();
        } else if (JSON.parse(e.data).type == "isChange") {
          console.log("isChange");
          this.upisChange(e);
          //消息未读总数
          this.UnMessage();
        }
      }
    },
    // 监听报错
    webSocketOnError(e) {
      console.log("与服务端连接异常->", e);
      //重连
      this.reconnect();
    },
    checkChatExist(conversationId) {
      for (let index = 0; index < this.dataList.length; index++) {
        const element = this.dataList[index];
        if (conversationId == element.conversationId) {
          return true;
        }
      }
      return false;
    },
    // 监听连接关闭
    webSocketOnClose(e) {
      console.log("与服务端连接关闭->", e);
      if (e.code === 1000) {
        console.log("WebSocket连接正常关闭");
      } else {
        if (e.code === 1001) {
          console.log("WebSocket连接由于终端离开而关闭");
        } else if (e.code === 1002) {
          console.log(
            e.code +
              " " +
              e.reason +
              " " +
              e.wasClean +
              "WebSocket连接由于协议错误而关闭"
          );
        } else if (e.code === 1003) {
          console.log("WebSocket连接由于接收到的数据类型错误而关闭");
        } else if (e.code === 1005) {
          console.log("WebSocket连接由于无法获取状态码而关闭");
        } else if (e.code === 1006) {
          console.log("WebSocket连接由于连接被重置而关闭");
          console.log(e);
        } else if (e.code === 1011) {
          console.log("WebSocket连接由于服务器端发生异常而关闭");
        } else if (e.code === 1015) {
          console.log("WebSocket连接由于TLS握手失败而关闭");
        }
        //重连
        this.reconnect();
      }
    },
    // 重新连接
    reconnect() {
      if (this.lockReconnect) {
        return;
      }

      this.lockReconnect = true;

      // 取消之前的重连请求
      clearTimeout(this.timeoutnum);

      // 设置新的重连逻辑
      this.timeoutnum = setTimeout(() => {
        try {
          this.webSocketInit(); // 尝试重新连接
        } catch (error) {
          console.error("重连过程中出现错误:", error); // 错误处理
        } finally {
          this.lockReconnect = false; // 确保锁定状态被释放
        }
      }, 5000);
    },

    // // // 重置心跳
    // reset() {
    //   var that = this;
    //   //清除时间
    //   clearTimeout(that.timeoutObj);
    //   clearTimeout(that.serverTimeoutObj);
    //   //重启心跳
    //   that.start();
    // },

    // // 开启心跳
    // start() {
    //   var self = this;
    //   self.timeoutObj && clearTimeout(self.timeoutObj);
    //   self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    //   self.timeoutObj = setTimeout(function () {
    //     //这里发送一个心跳，后端收到后，返回一个心跳消息，
    //     if (self.webSocketObject.readyState == 1) {
    //       let Prompt = {
    //         heart: "Hello",
    //       };
    //       let HeartbeatDetection = JSON.stringify(Prompt);
    //       self.webSocketObject.send(HeartbeatDetection);
    //     } else {
    //       //否则重连
    //       self.reconnect();
    //     }
    //     self.serverTimeoutObj = setTimeout(function () {
    //       //超时关闭
    //       self.webSocketObject.close();
    //     }, self.timeout);
    //   }, self.timeout);
    // },
    //查询总的未读消息
    async UnMessage() {
      let params = {
        receiverId: this.identityObj.identityId,
      };
      let res = await UnreadMessage(params);
      let AllMessages = res.data;
      this.SET_MESSAGE(AllMessages);
    },

    xzqChange(val) {
      this.setTopSelectAreacode(val[val.length - 1]);
    },
    //在线沟通
    async Online() {
      this.$router.push("/home/Chat");
    },
    async getRegions() {
      let res = await getXzqArr({
        level: 3,
      });
      if (res.code == 0) {
        this.options = removeEmptyChildren(res.data, "children");
      }
    },
    menuHandle(name) {
      this.$router.push({
        name: name,
      });

      this.currentMenu = name;
    },
    userLogin() {
      if (this.userInfo.identityList) {
        if (this.userInfo.identityList.length > 0) {
          this.$router.push({ name: "chooseRole" });
        } else {
          this.$router.push({ name: "login" });
        }
      } else {
        // 用户登录
        this.$router.push({ name: "login" });
      }
    },
    userRegister() {
      // 用户注册
      this.$router.push({ name: "register" });
    },
    transMenu(tree, parentName = "") {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        const fullName = parentName + "/" + node.path; // 拼接全称
        if (node.children) {
          this.transMenu(node.children, fullName); // 递归调用函数遍历子节点
        }
        node.allPath = fullName; // 赋值全称给节点
      }
      return tree;
    },
    async getOthersInfo() {
      try {
        let res = await getUserMenuByIdentity();

        console.log("121241456", res);
        if (res.code == 0 && res.data.menuList.length > 0) {
          res.data.menuList.forEach((item) => {
            if (item.path === "memberCenter") {
              let arr = this.transMenu([item][0].children, "/memberCenter");
              console.log("1", arr);
              if (arr.length > 0) {
                this.setVipTree(arr);
                this.$router.$avueRouter.formatRoutes(arr, true);
                if (arr[0].children.length == 0) {
                  this.$router.push({
                    path: arr[0].allPath,
                  });
                } else {
                  if (arr[0].children.length > 0) {
                    this.$router.push({
                      path: arr[0].children[0].allPath,
                    });
                  }
                }
              }
            }
          });
        }
      } catch (error) {
        console.log("错误", error);
      }
    },
    handleCommand(command) {
      // 下拉菜单点击
      if (command == 0) {
        // 会员中心
        let arr = this.vipLeftTree;
        console.log("arr", arr);
        if (!this.userInfo.identityList) {
          this.$router.push({ name: "login" });
        } else {
          if (this.identityObj.identityName && arr.length == 0) {
            this.getOthersInfo();
          }
          if (arr.length > 0) {
            if (arr[0].children.length == 0) {
              this.$router.push({
                path: arr[0].allPath,
              });
            } else {
              if (arr[0].children.length > 0) {
                this.$router.push({
                  path: arr[0].children[0].allPath,
                });
              }
            }
          }
        }
      } else {
        // this.$router.push({name: 'homepage'})
        this.$confirm("是否退出系统, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          localStorage.clear();
          if (this.webSocketObject) {
            this.SET_MESSAGE(0);
            this.webSocketObject.close();
          }
          this.$store.dispatch("LogOut").then(() => {
            // location.reload();
            // this.$router.go(0);
            this.showOpt = "";
            this.$router.push({ path: "/home/homepage" });
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 1600px !important;
  height: 66px;
  margin: auto;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  // position: fixed;
  background: #fff;

  .title {
    height: 57px;
    line-height: 57px;
    display: flex;
    font-weight: bold;
    font-size: 30px;
    color: #333;
    /deep/.el-input--suffix {
      font-size: 24px !important;
      ::placeholder {
        font-size: 20px !important;
      }
    }
    img {
      height: 43px;
      margin-top: 4px;
    }

    .el-select {
      width: 90px;
    }
  }
  .operation {
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    .login {
      font-size: 14px;
      margin-left: 37px;
      color: #8d8d8d;
      display: flex;
      align-items: center;
      span {
        cursor: pointer;
        margin: 0px 6px;
      }

      .with-icon {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
    }
    .search {
      border: 1px solid #ccc;
      border-radius: 42px;
      .el-button {
        width: 55px;
        height: 36px;
        padding: 0 6px;
        background: transparent;
        border-color: transparent;
        color: #333;
        // background-color: #00a0e9;
        line-height: 35px;

        img {
          vertical-align: middle;
          margin-right: 5px;
          margin-bottom: 3px;
        }
      }
    }
  }

  .user {
    .photo {
      // width: 50px;
      border-radius: 25px;
      display: inline-block;
      line-height: 60px;
      img {
        background-color: #e3e3e3;
        height: 50px;
        width: 50px;
        border-radius: 25px;
        vertical-align: middle;
        margin-right: 10px;
        // width: 100%;
        // height: 100%;
      }
    }
  }

  .menu {
    ul {
      display: flex;
      li {
        list-style: none;
        margin: 0px 25px;
        color: #333;
        font-weight: bold;

        a:hover {
          color: #0668b3;
          cursor: pointer;
        }

        &.active {
          color: #0668b3;
          position: relative;

          &::before {
            position: absolute;
            content: "";
            width: 36px;
            height: 3px;
            bottom: -8px;
            left: calc(50% - 18px);
            background: #0668b3;
          }
        }
      }
    }
  }
  .ChatFrame {
    border: none;
  }
}
</style>

<style lang="less">
.search {
  .el-input__inner {
    height: 35px;
    border: transparent;
    border-radius: 32px;

    &::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      color: #999;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 适配火狐 */
      color: #999;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ 适配火狐 */
      color: #999;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+  适配ie*/
      color: #999;
    }
  }
}

.title {
  .el-input__inner {
    border: 0;
  }
}
</style>
